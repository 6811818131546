exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bienfaits-de-la-sophrologie-js": () => import("./../../../src/pages/bienfaits-de-la-sophrologie.js" /* webpackChunkName: "component---src-pages-bienfaits-de-la-sophrologie-js" */),
  "component---src-pages-comment-se-deroule-une-seance-de-sophrologie-js": () => import("./../../../src/pages/comment-se-deroule-une-seance-de-sophrologie.js" /* webpackChunkName: "component---src-pages-comment-se-deroule-une-seance-de-sophrologie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ma-sophrologue-paris-js": () => import("./../../../src/pages/ma-sophrologue-paris.js" /* webpackChunkName: "component---src-pages-ma-sophrologue-paris-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-politique-de-confidentialite-js": () => import("./../../../src/pages/politique-de-confidentialite.js" /* webpackChunkName: "component---src-pages-politique-de-confidentialite-js" */),
  "component---src-pages-pratiques-de-la-sophrologie-js": () => import("./../../../src/pages/pratiques-de-la-sophrologie.js" /* webpackChunkName: "component---src-pages-pratiques-de-la-sophrologie-js" */),
  "component---src-pages-que-soigne-la-sophrologie-js": () => import("./../../../src/pages/que-soigne-la-sophrologie.js" /* webpackChunkName: "component---src-pages-que-soigne-la-sophrologie-js" */),
  "component---src-pages-questions-frequentes-js": () => import("./../../../src/pages/questions-frequentes.js" /* webpackChunkName: "component---src-pages-questions-frequentes-js" */),
  "component---src-pages-seance-de-sophrologie-js": () => import("./../../../src/pages/seance-de-sophrologie.js" /* webpackChunkName: "component---src-pages-seance-de-sophrologie-js" */),
  "component---src-pages-suivi-et-accompagnement-js": () => import("./../../../src/pages/suivi-et-accompagnement.js" /* webpackChunkName: "component---src-pages-suivi-et-accompagnement-js" */)
}

